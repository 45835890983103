import { TableFilterInputText } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { Button, CreateAlert, Icon, InputWraper, Table, useForm } from "../../../modules";
import React, { useEffect, useState } from "react"
import { AdminService } from "../../../services/admin";
import { InputSelect, InputText } from "../../../components";
import "./index.scss"
import * as Yup from "yup";
import { PopupWraper } from "../../../components/popup";
import moment from "moment";
import { translate } from "../../../languages";
import { ETournamentStatus } from "../../../types";
import _ from "lodash";
import { InputImageSingle } from "../../../components/single-image";
import Swal from 'sweetalert2';
import { getEnv } from "../../../configs";
import { withBuyInWraper } from "../wraper";

export const BuyIn = withBuyInWraper(() => {

    const [tournamentList, setTournamentList] = useState<any>();
    const [eventPickedData, setEventPickedData] = useState<any>({});
    const [userPickedData, setUserPickedData] = useState<any>({});
    const [isRequesting, setIsRequesting] = useState<boolean>(false);

    const { handleSubmit, getInputProps, resetForm, validateForm, isValid } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: "tourId",
                validate: Yup.number().required(translate("must-be-provided")),
            },
            {
                name: "eventId",
                validate: Yup.number().required(translate("must-be-provided")),
                isDisabled: true,
            },
            {
                name: "userId",
                validate: Yup.number().required(translate("must-be-provided")),
                isDisabled: true,
            },
            {
                name: "billImage",
                validate: Yup.string().required("must-be-provided"),
            },
        ],
        onSubmit: async (values) => {

            let payload = {
                eventId: +values?.eventId,
                userId: +values?.userId,
                billImage: values?.billImage?.src,
                isNoCharge: false,
            };

            return AdminService.createBuyIn(payload)
                .then(() => {
                    resetForm();
                    setEventPickedData({});
                    setUserPickedData({});
                    CreateAlert({ message: "Buy-in successfully" , type: "success" });
                })
                .catch((error) => {
                    CreateAlert({ message: translate(error.message), type: "danger" });
                });
        },
    });

    let handleOnClickOnInputPickEvent = () => {
        if ((getInputProps("tourId")?.value ?? '') === '') return;
        setEventPickedData(null);
    };

    let handleOnClickOnInputPickUser = () => {
        if ((getInputProps("tourId")?.value ?? '') === '') return;
        setUserPickedData(null);
    };

    let handleOnClickPickEvent = (item: any) => {
        getInputProps("eventId").onChange(item?.tourEventId);
        setEventPickedData(item);
    };

    let handleOnClickPickUser = (item: any) => {
        getInputProps("userId").onChange(item?.userId);
        setUserPickedData(item);
    };

    let handleOnClickBeforeSubmit = async (e:any) => {
        e.preventDefault();
        if (_.isEmpty(await validateForm()) === false) return; // Validate form
        if (isRequesting) return;
        setIsRequesting(true);
        AdminService.getUserBalance({ userId: +getInputProps("userId")?.value }).then((res) => {
            Swal.fire({
                title: `Are you sure to submit this buy-in?`,
                icon: "warning",
                html: `
                    <div class="info">
                        <div class="info__user">User: <strong>${userPickedData?.firstName}</strong> - <strong>${userPickedData?.idCard}</strong></div>
                        <div class="info__current-balance">Current Balance: <strong>${(res?.result != null) ? `${new Intl.NumberFormat('en-US', { minimumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY"), maximumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY") }).format(+res?.result)} ${eventPickedData?.currency}` : 'N/A'}</strong></div>
                        <div class="info__amount">Amount: <strong>${(eventPickedData?.buyInFee != null) ? `${new Intl.NumberFormat('en-US', { minimumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY"), maximumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY") }).format(+eventPickedData?.buyInFee)} ${eventPickedData?.currency}` : 'N/A'}</strong></div>
                        <div class="info__es">Remaining Balance: <strong>${(res?.result != null && eventPickedData?.buyInFee != null) ? `${new Intl.NumberFormat('en-US', { minimumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY"), maximumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY") }).format(Number(res?.result) - Number(eventPickedData?.buyInFee))} ${eventPickedData?.currency}` : 'N/A'}</strong></div>
                    </div>
                `,
                showCancelButton: true,
                confirmButtonText: 'Submit',
                heightAuto: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSubmit();
                }
            });
        }).catch((err) => {
            CreateAlert({ type: "danger", message: translate(err.message), });
        }).finally(() => {
            setIsRequesting(false);
        });
    };

    useEffect(() => {
        AdminService.getTournamentList({
            page: 1,
            pageSize: 10000,
            status: ETournamentStatus.ONGOING,
        }).then((res:any) => {
            setTournamentList((res?.data ?? [])?.map((item: any) => ({ label: item?.tourName, value: item?.tourId })));
        });
    }, []);

    if (tournamentList == null) return null;

    return (
        <div className="buy-in-page">
            <div className="row">
                <div className="col-6">
                    <div className="buy-in">
                        <div className="buy-in__title">BUY IN</div>
                        <form className="buy-in__form" onSubmit={(e) => handleOnClickBeforeSubmit(e)}>
                            <InputWraper
                                label={"Pick a Tournament"}
                                inputProps={getInputProps("tourId")}
                                renderInput={(props) => (
                                    <InputSelect
                                        {...props}
                                        placeholder="Select a tournament"
                                        options={tournamentList}
                                        onChange={(value) => {
                                            setUserPickedData({});
                                            setEventPickedData({});
                                            getInputProps("userId").onChange(undefined);
                                            getInputProps("eventId").onChange(undefined);
                                            getInputProps("tourId").onChange(value);
                                        }}
                                        value={getInputProps("tourId")?.value}
                                        isClearable={false}
                                        isSearchable={false}
                                    />
                                )}
                            />
                            <InputWraper
                                label={"Pick a Event (Event Ref - Event Name - Buy-in+Fee)"}
                                inputProps={getInputProps("eventId")}
                                onClickOnInput={handleOnClickOnInputPickEvent}
                                renderInput={(props) => (
                                    <InputText
                                        {...props}
                                        placeholder="Click here to pick one"
                                        value={(_.isEmpty(eventPickedData) === false) ? `${eventPickedData?.eventRef} - ${eventPickedData?.tourEventName} - ${new Intl.NumberFormat('en-US').format(eventPickedData?.buyInFee)} ${eventPickedData?.currency}` : ""}
                                    />
                                )}
                            />
                            <InputWraper
                                label={"Pick a User (Full Name - IDentity Card/Passport)"}
                                inputProps={getInputProps("userId")}
                                onClickOnInput={handleOnClickOnInputPickUser}
                                renderInput={(props) => (
                                    <InputText
                                        {...props}
                                        placeholder="Click here to pick one"
                                        value={(_.isEmpty(userPickedData) === false) ? `${userPickedData?.firstName} - ${userPickedData?.idCard}` : ""}
                                    />
                                )}
                            />
                            <InputWraper 
                                label={"Bill Image"} 
                                inputProps={getInputProps("billImage")}
                                component={InputImageSingle} 
                            />

                            <Button
                                label="Submit"
                                type="submit"
                                buttonType="success"
                                isMiddle
                                disabled={isRequesting}
                                isLoading={isRequesting}
                            />
                        </form>
                    </div>
                </div>
            </div>
            {(eventPickedData == null) && (
                <PopupWraper
                    className="pick-event-popup"
                    title="Pick a Event"
                    onClose={() => {
                        getInputProps("eventId").onChange(undefined);
                        setEventPickedData({});
                    }}
                >
                    <Table
                        hasOrderColumn
                        isOpenFilter
                        structure={[
                            {
                                name: "EVENT ID",
                                key: "eventRef",
                                render: (item: any) => item?.eventRef ?? "N/A",
                            },
                            {
                                name: "EVENT NAME",
                                key: "tourEventName",
                                render: (item: any) => item?.tourEventName ?? "N/A",
                            },
                            {
                                name: "BUY-IN+FEE",
                                key: "buyInFee",
                                render: (item: any) => (item?.buyInFee != null ? `${new Intl.NumberFormat("en-US").format(item?.buyInFee)} ${item?.currency ?? ""}` : "N/A"),
                            },
                            {
                                name: "REG END",
                                key: "regEnd",
                                render: (item: any) => (item?.regEnd != null ? moment(item?.regEnd).format("L HH:mm:ss") : "N/A"),
                            },
                            {
                                name: "START TIME",
                                key: "startTime",
                                render: (item: any) => (item?.startTime != null ? moment(item?.startTime).format("L HH:mm:ss") : "N/A"),
                            },
                            {
                                name: "ACTION",
                                key: "action",
                                render: (item: any) => {
                                    return (
                                        <div className="action">
                                            <div className="action__edit" title="Pick this user" onClick={() => handleOnClickPickEvent(item)}>
                                                <Icon.AddIcon />
                                            </div>
                                        </div>
                                    );
                                },
                            },
                        ]}
                        itemPerPages={5}
                        fetchData={async (params) => {
                            return AdminService.getEventOnGoingList({
                                tourId: +getInputProps("tourId")?.value,
                                page: params.pageNumber,
                                pageSize: params.limit,
                                ...params,
                            });
                        }}
                    />
                </PopupWraper>
            )}
            {(userPickedData == null) && (
                <PopupWraper
                    className="pick-user-popup"
                    title="Pick a User"
                    onClose={() => {
                        getInputProps("userId").onChange(undefined);
                        setUserPickedData({});
                    }}
                >
                    <Table
                        hasOrderColumn
                        isOpenFilter
                        filters={[
                            {
                                name: "Search",
                                key: "keyword",
                                input: e => <TableFilterInputText {...e} placeholder="Full Name, Identity Card/Passport" />,
                            }
                        ]}
                        structure={[
                            {
                                name: "C9 ID",
                                key: "userId",
                                render: (item: any) => item?.userId ?? "N/A",
                                copyable: (item: any) => item?.userId,
                            },
                            {
                                name: "FULL NAME",
                                key: "firstName",
                                render: (item: any) => item?.firstName ?? "N/A",
                                copyable: (item: any) => item?.firstName,
                            },
                            {
                                name: "NICK NAME",
                                key: "nick",
                            },
                            {
                                name: "EMAIL",
                                key: "mail",
                            },
                            {
                                name: "PHONE",
                                key: "phoneNumber",
                                render: (item: any) => item?.phoneNumber ?? "N/A",
                                copyable: (item: any) => item?.phoneNumber,
                            },
                            {
                                name: "IDENTITY CARD/PASSPORT",
                                key: "idCard",
                                copyable: (item: any) => item?.idCard,
                            },
                            {
                                name: "NATIONALITY",
                                key: "languageName",
                                copyable: (item: any) => item?.languageName,
                            },
                            {
                                name: "ACTIONS",
                                key: "actions",
                                render: (item: any) => {
                                    return (
                                        <div className="action">
                                            <div className="action__edit" title="Pick this user" onClick={() => handleOnClickPickUser(item)}>
                                                <Icon.AddIcon />
                                            </div>
                                        </div>
                                    );
                                },
                            },
                        ]}
                        itemPerPages={5}
                        fetchData={async (params) => {
                            return AdminService.getTournamentparticipantList({
                                tourId: +getInputProps("tourId")?.value,
                                isPackage: true,
                                page: params.pageNumber,
                                pageSize: params.limit,
                                ...params,
                            });
                        }}
                    />
                </PopupWraper>
            )}
        </div>
    )
});