import moment from "moment";
import React, { useState } from "react";
import XLSX from "xlsx";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { translate } from "../../../languages";
import { Button, CreateAlert, Icon, ITableStructureItem, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { useSelector } from "../../../store";
import { withUserWraper } from "../wraper";
import { EApprovalActionType, EUserApprovalStatus } from "../../../types";
import Lightbox from "yet-another-react-lightbox";
import Swal from 'sweetalert2';
// import "./styles.css";

export const UserReviewPage = withUserWraper(() => {
    const countries = useSelector((state) => state.countries);
    const [params, setParams] = useState([] as any[]);
    const [openLightboxData, setOpenLightboxData] = useState<any>();

    let handleOnClickApproval = async (item: any, action: any, fetchData: any) => {
        if (item?.approvalStatus !== EUserApprovalStatus.PENDING) return;
        Swal.fire({
            title: `Are you sure to ${action} this user?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: (action === EApprovalActionType.APPROVE) ? 'Approve' : 'Reject',
            showLoaderOnConfirm: true,
            heightAuto: false,
            preConfirm: async (value) => {
                let payload = {
                    userChangeHistoryId: item?.userChangeHistoryId,
                    isApprove: (action === EApprovalActionType.APPROVE) ? true : false,
                };
                return AdminService.approveReviewUser(payload).then((res) => {
                    CreateAlert({ type: "success", message: `${(action === EApprovalActionType.APPROVE) ? "Approve" : "Reject"} success`, });
                    fetchData();
                }).catch((err) => {
                    CreateAlert({ type: "danger", message: translate(err.message), });
                }).finally(() => {
                });
            },
        });
    };

    const structure: ITableStructureItem[] = [
        {
            name: "FULL NAME",
            key: "currentData.firstName",
            render: (item) => <span style={{color: '#4680FF'}}>{item?.currentData?.firstName ?? 'N/A'}</span>,
            copyable: (item) => item?.currentData?.firstName,
        },
        {
            name: "NICKNAME",
            key: "currentData.nick",
            render: (item) => item?.currentData?.nick ?? 'N/A',
            copyable: (item) => item?.currentData?.nick,
        },
        {
            name: "EMAIL",
            key: "currentData.email",
            render: (item) => {
                return item?.currentData?.email;
            },
            copyable: (item) => item?.currentData?.email,
        },
        {
            name: "PHONE",
            key: "currentData.phoneNumber",
            render: (item) => item?.currentData?.phoneNumber ?? 'N/A',
            copyable: (item) => item?.currentData?.phoneNumber,
        },
        {
            name: "IDENTITY CARD/\nPASSPORT",
            key: "currentData.idCard",
            render: (item) => (
                <div className="id-card">
                    <div className="id-card__label">{item?.currentData?.idCard ?? 'N/A'}</div>
                    {((item?.currentData?.imageUrls ?? [])?.length > 0) && (
                        <div className="id-card__icon" onClick={() => setOpenLightboxData((item?.currentData?.imageUrls ?? []))}><Icon.Visible /></div>
                    )}
                </div>
            ),
            copyable: (item) => item?.currentData?.idCard,
        },
        {
            name: "NATIONALITY",
            key: "currentData.countryId",
            render: (item) => (item?.currentData?.countryId != null ? countries?.data?.find((x: any) => x.countryId === item?.currentData?.countryId)?.niceName : 'N/A'),
            copyable: (item) => (item?.currentData?.countryId != null ? countries?.data?.find((x: any) => x.countryId === item?.currentData?.countryId)?.niceName : 'N/A'),
        },
        {
            name: "TIME",
            key: "modified",
            render: (item) => (item?.modified != null) ? moment(item?.modified).format('L HH:mm:ss') : 'N/A',
        },
        {
            name: "TYPE",
            key: "action",
            render: (item) => <span className={`action action--${item?.action?.toLowerCase()}`}>{(item?.action != null) ? translate(`IUserChangeHistoryAction.${item?.action}`) : 'N/A'}</span>,
        },
        {
            name: "STATUS",
            key: "approvalStatus",
            render: (item) => <span className={`status status--${item?.approvalStatus?.toLowerCase()}`}>{item?.approvalStatus ?? 'N/A'}</span>,
        },
        {
            name: "ACTIONS",
            key: "actions",
            render: (item, fetchData) => {
                return (
                    <div className="action">
                        {item?.approvalStatus === EUserApprovalStatus.PENDING && (<>
                            <div className="action__approve">
                                <Button
                                    label="Approve"
                                    buttonType="success"
                                    onClick={() => handleOnClickApproval(item, EApprovalActionType.APPROVE, fetchData)}
                                />
                            </div>
                            <div className="action__reject">
                                <Button
                                    label="Reject"
                                    buttonType="danger"
                                    onClick={() => handleOnClickApproval(item, EApprovalActionType.REJECT, fetchData)}
                                />
                            </div>
                        </>)}
                    </div>
                );
            },
        },
    ];

    const handleExportExcel = async () => {
        return new Promise(async (resolve) => {
            try {
                const response = await AdminService.getUserReviewList({
                    ...params,
                    page: 1,
                    numberOfItemsPerPage: 1000000,
                });

                const data = response.data;

                const fileHead = structure.map((v) => v.name);
                const dataExport = [
                    fileHead,
                    ...data.map((item: any) =>
                        structure.map((column, index) => {
                            if (!column.key) return "";
                            if (column.key === "countryId") return countries?.data?.find((x: any) => x.countryId === item?.countryId)?.niceName;
                            return item[column.key];
                        }),
                    ),
                ];

                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                const now = new Date();
                XLSX.writeFile(wb, `User List ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, { type: "binary" });

                resolve(
                    CreateAlert({
                        type: "success",
                        message: "Export data success.",
                    }),
                );
            } catch (error) {
                // @ts-ignore
                resolve(CreateAlert({ type: "danger", message: error.message }));
            }
        });
    };

    return (
        <div className="user-review-page">
            <div className="button-container">
                <Button
                    className="mb20"
                    label="Export to Excel"
                    buttonType="success"
                    onClick={handleExportExcel}
                />
            </div>

            <Table
                isOpenFilter
                hasOrderColumn
                filters={[
                    {
                        name: 'Status',
                        key: "approvalStatus",
                        input: (e) => (
                            <TableFilterInputSelect
                                {...e}
                                options={[
                                    { label: "PENDING", value: EUserApprovalStatus.PENDING },
                                    { label: "APPROVED", value: EUserApprovalStatus.APPROVED },
                                    { label: "REJECTED", value: EUserApprovalStatus.REJECTED },
                                ]}
                                isClearable={true}
                                isSearchable={false}
                            />
                        ),
                        defaultValue: EUserApprovalStatus.PENDING,
                    },
                ]}
                structure={structure}
                fetchData={async (params) => {
                    setParams(params);
                    return AdminService.getUserReviewList({
                        page: params.pageNumber,
                        numberOfItemsPerPage: params.limit,
                        ...params,
                    });
                }}
            />

            <Lightbox
                open={openLightboxData != null}
                close={() => setOpenLightboxData(null)}
                slides={[
                    ...(openLightboxData ?? []).map((x: any) => ({ src: x })),
                ]}
                carousel={{ 
                    finite: true,
                }}
            />
        </div>
    );
});
