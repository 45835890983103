import React from "react";
import { getEnv } from "../../configs";

const BalanceText = ({amount}:{amount:number|undefined}) => {
    if(amount===undefined) return <span>N/A</span>;
    let colorClass = "textSuccess"
    if(amount<0){
        colorClass="textDanger"
    }
    else if(amount<10000000){
        colorClass="textWarning"
    }
    return <span className={`${colorClass}`}>{new Intl.NumberFormat('en-US', { minimumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY"), maximumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY") }).format(amount)}</span>
}
 
export default BalanceText;