import React, { FC } from "react";
import { UserService } from "../../services/user";
import { translate } from "../../languages";
import { Icon } from "../icon";
import { NavLink, useLocation } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { useSelector } from "../../store";

export const Navigator: FC = () => {
  return (
    <div className="Navigator">
      {/* <img src="/assets/images/logo-symbol-white.png" alt="" className="logo" /> */}
      <MenuLinks />
    </div>
  );
};

const MenuLinks: FC = () => {
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user);
  return (
    <div className="menu">
      {/* <NavLink exact to={Routes.dashboard.path}>
                <Icon.Dashboard />
                Dashboard
            </NavLink>

            <NavLink to={Routes.userList.path}>
                <Icon.User />
                Users
            </NavLink>

            <NavLink to={Routes.transactionList.path}>
                <Icon.Exchange />
                Transactions
            </NavLink>

            <NavLink to={Routes.ClaimList.path}>
                <Icon.ClaimIcon />
                Claim
            </NavLink> */}

            {/* <NavLink to={Routes.reportSystem.path}>
                <Icon.Report />
                Reports
            </NavLink>

            <NavLink to={Routes.withdrawConfig.path}>
                <Icon.Setting />
                Config
            </NavLink>

            <div className="btnLogout" onClick={() => UserService.logout()}>
                <Icon.Logout />
                {translate('logout')}
            </NavLink> */}

      <NavLink
        to={Routes.userList.path}
        isActive={() => {
          return pathname.includes("/user-promotion") || pathname.includes("/user-list") || (pathname === Routes.dashboard.path) || (pathname === Routes.userReview.path) || (pathname.startsWith('/user/'));
        }}
      >
        <Icon.User />
        Users
      </NavLink>

      <NavLink
        to={Routes.tournamentList.path}
        isActive={() => {
          return pathname.includes(Routes.tournamentList.path);
        }}
      >
        <Icon.TournamentIcon />
        Tournament
      </NavLink>

      <NavLink
        to={Routes.packageList.path}
        isActive={() => {
          return pathname.includes(Routes.packageList.path);
        }}
      >
        <Icon.PackageIcon />
        Package
      </NavLink>

      <NavLink
        to={Routes.transactionList.path}
        isActive={() => {
          return pathname.includes("/transaction-list") || pathname.includes("/transactions-withdraws");
        }}
      >
        <Icon.TransactionIcon />
        Transactions
      </NavLink>

      <NavLink to={Routes.franchiseTable.path}>
          <Icon.Affiliate />
          Franchise
      </NavLink>
      <NavLink 
          to={Routes.buyIn.path}
          isActive={() => {
            return pathname.includes("/buy-in") || pathname.includes("/buy-in-without-package");
          }}
      >
          <Icon.BuyInIcon />
          Buy In
      </NavLink>
      <NavLink
        to={Routes.sponsorshipAndCommissionList.path}
        isActive={() => {
          return pathname.startsWith(Routes.sponsorshipAndCommissionList.path);
        }}
      >
        <Icon.SponsorshipAndCommissionIcon />
        Sponsorship & Commission
      </NavLink>
      {/* 
      <NavLink to={Routes.ExperienceGameList.path}>
        <Icon.ExperienceGameIcon />
        Experience Game
      </NavLink>

      <NavLink to={Routes.ClaimList.path}>
        <Icon.ClaimIcon />
        Claim
      </NavLink> */}

      {/* <NavLink
        to={Routes.franchiseTable.path}
        isActive={() => {
          return (
            pathname.includes("/franchise-table") || pathname.includes("/franchise-tree") || pathname.includes("/franchise-level-setting-history")
          );
        }}
      >
        <Icon.Affiliate />
        Franchise
      </NavLink> */}
      {/* 
      {user?.userRole !== "AGENT" && (
        <NavLink
          to={Routes.OrdersPage.path}
          isActive={() => {
            return (
              pathname.includes("/game-management-orders") ||
              pathname.includes("/game-management-supplier") ||
              pathname.includes("/game-management-game")
            );
          }}
        >
          <Icon.GameManagementIcon />
          Game Management
        </NavLink>
      )} */}

      {/* <NavLink to={Routes.GameOrdersList.path}>
        <Icon.GameOrdersIcon />
        Orders
      </NavLink> */}

      {/* <NavLink
        to={Routes.DepositFiat.path}
        isActive={() => {
          return pathname.includes("/fiat/deposit") || pathname.includes("/fiat/withdraw");
        }}
      >
        <Icon.TransactionIcon />
        Fiat
      </NavLink>

      <NavLink
        to={Routes.depositDIG.path}
        isActive={() => {
          return (
            pathname.includes("/dig/transactions") ||
            pathname.includes("/dig/withdraw") ||
            pathname.includes("/dig/balance") ||
            pathname.includes("/dig/device") ||
            pathname.includes("/dig/device/history") ||
            pathname.includes("/dig/bank") ||
            pathname.includes("/dig/bank/transfer-history") ||
            pathname.includes("/dig/bank/matching-history")
          );
        }}
      >
        <Icon.TransactionIcon />
        DIG
      </NavLink> */}

      <NavLink to={Routes.reportSystem.path}>
        <Icon.Report />
        Reports
      </NavLink>

      {/* <NavLink to={Routes.agent.path}>
        <Icon.Account />
        Agent
      </NavLink> */}

      {user?.userRole !== "AGENT" && (
        <NavLink
          to={Routes.changePassword.path}
          isActive={() => {
            return (
              // pathname.includes("/server-config-withdraw") ||
              pathname.includes("/server-config-change-password") ||
              pathname.includes("/server-config-activities") ||
              pathname.includes("/server-config-maintenance") ||
              pathname.includes("/server-config-role-permission")
            );
          }}
        >
          <Icon.Setting />
          Settings
        </NavLink>
      )}

      <div className="btnLogout" onClick={() => UserService.logout()}>
        <Icon.Logout />
        {translate("logout")}
      </div>
    </div>
  );
};
