import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, CreateAlert, Icon, InputWraper, onError, Table, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { InputCheckbox, InputDateTime, InputNumber, InputSelect, InputText, InputTextArea } from "../../../components";
import { InputImageSingle } from "../../../components/single-image";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";
import { Routes } from "../../../AppRoutes";
import { PopupWraper } from "../../../components/popup";
import { translate } from "../../../languages";
import { v4 as uuidv4 } from "uuid";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";
import _ from "lodash";
import { currencyOptions, organizationList } from "../create";
import Swal from 'sweetalert2';
import { EApprovalActionType, ETournamentRegisterStatus } from '../../../types';
import { getEnv } from "../../../configs";
import BalanceText from "../../../components/balance";

export const TournamentEditPage = () => {
  const { params }: any = useRouteMatch();
  const history = useHistory();

  const [dataDetail, setDataDetail] = useState<any>();
  const [scheduleData, setScheduleData] = useState<any>([]);
  const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);

  let handleOnClickApproval = async (item: any, action: any, fetchData: any) => {
      if (item?.status !== ETournamentRegisterStatus.AGENT_REGISTERED) return;
      Swal.fire({
          title: `Are you sure to ${action} this user?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: (action === EApprovalActionType.APPROVE) ? 'Approve' : 'Reject',
          showLoaderOnConfirm: true,
          input: (action === EApprovalActionType.REJECT) ? 'text' : undefined,
          inputPlaceholder: (action === EApprovalActionType.REJECT) ? 'Enter reason for rejection' : undefined,
          heightAuto: false,
          preConfirm: async (value) => {
              let payload = {
                userId: item?.userId,
                tourId: dataDetail?.tourId,
                status: (action === EApprovalActionType.APPROVE) ? ETournamentRegisterStatus.CONFIRM : ETournamentRegisterStatus.REJECTED,
                ...(action === EApprovalActionType.REJECT) && { reason: value }
              };
              return AdminService.updateStatusTournament(payload).then((res: any) => {
                CreateAlert({ type: "success", message: `${(action === EApprovalActionType.APPROVE) ? "Approve" : "Reject"} success`, });
                fetchData();
              })
              .catch((err: any) => {
                CreateAlert({ message: err?.message, type: "danger" });
              });
          },
      });
  };

  const { getInputProps, handleSubmit } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "tourName",
        validate: Yup.string().required("Must be provided"),
        defaultValue: dataDetail?.tourName,
      },
      {
        name: "location",
        validate: Yup.string().required("Must be provided"),
        defaultValue: dataDetail?.location,
      },
      {
        name: "organization",
        validate: Yup.string().required("Must be provided"),
        defaultValue: dataDetail?.organization,
      },
      {
        name: "startTime",
        validate: Yup.string().required("Must be provided"),
        defaultValue: dataDetail?.startTime,
      },
      {
        name: "endTime",
        validate: Yup.string().required("Must be provided"),
        defaultValue: dataDetail?.endTime,
      },
      {
        name: "prize",
        validate: Yup.number().typeError("Must be a number").required("Must be provided").moreThan(0, "Must be greater than 0"),
        defaultValue: dataDetail?.prize,
      },
      {
        name: "currency",
        validate: Yup.string().required("Must be provided"),
        defaultValue: dataDetail?.currency,
        isDisabled: true,
      },
      {
        name: "promotion",
        validate: Yup.string().required("Must be provided"),
        defaultValue: { file: { name: dataDetail?.promotion }, src: dataDetail?.promotion },
      },
    ],
    onSubmit: async (values: any) => {
      let payload = {
        tourId: +params?.id,
        tourName: values?.tourName,
        location: values?.location,
        organization: values?.organization,
        startTime: moment(values?.startTime).toISOString(),
        endTime: moment(values?.endTime).toISOString(),
        prize: +values?.prize,
        currency: values?.currency,
        promotion: values?.promotion?.src,
      };

      AdminService.editTournament(payload)
        .then((res: any) => {
          history.push(Routes.tournamentList.path);
          CreateAlert({ message: "Edit tournament successfully.", type: "success" });
        })
        .catch((err: any) => {
          CreateAlert({ message: err?.message, type: "danger" });
        })
        .finally(() => {});
    },
  });

  let participantStructure: any = [
    {
      name: "ORGANIZATION ID",
      key: "cloud9Id",
      render: (item: any) => item?.cloud9Id ?? "N/A",
      copyable: (item: any) => item?.cloud9Id,
    },
    {
      name: "ORGANIZATION QR",
      key: "cloud9Qr",
      render: (item: any) =>
        item?.cloud9Qr != null ? (
          <span className="cloud9qr" onClick={() => window.open(item?.cloud9Qr, "_blank")}>
            <Icon.QRIcon />
          </span>
        ) : (
          "N/A"
        ),
      copyable: (item: any) => item?.cloud9Qr,
    },
    {
      name: "FULL NAME",
      key: "firstName",
      render: (item: any) => item?.firstName ?? "N/A",
      copyable: (item: any) => item?.firstName,
    },
    // {
    //   name: "NICKNAME",
    //   key: "nick",
    //   render: (item: any) => item?.nick ?? "N/A",
    //   copyable: (item: any) => item?.nick,
    // },
    // {
    //   name: "NATIONALITY",
    //   key: "languageName",
    //   render: (item: any) => item?.languageName ?? "N/A",
    //   copyable: (item: any) => item?.languageName,
    // },
    {
      name: "AGENT NAME",
      key: "agentName",
      render: (item: any) => item?.agentName ?? "N/A",
      copyable: (item: any) => item?.agentName,
    },
    {
      name: "REGISTRATION STATUS",
      key: "status",
      render: (item: any) => <span className={`status status--${item?.status?.toLowerCase()}`}>{_.startCase(_.toUpper(item?.status))}</span>,
    },
    {
      name: "BALANCE",
      key: "balance",
      render: (item: any) =>  <BalanceText amount={item?.balance}/>,
      //   {
      //   if(item?.balance===undefined) return "N/A";
      //   if(item?.balance<10000000)
      //   return <span className="textDanger">{new Intl.NumberFormat('en-US', { minimumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY"), maximumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY") }).format(item?.balance)}</span>
      //   else return <span className="textSuccess">{new Intl.NumberFormat('en-US', { minimumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY"), maximumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY") }).format(item?.balance)}</span>
      // }
        // (item?.balance != null) ? `${new Intl.NumberFormat('en-US', { minimumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY"), maximumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY") }).format(item?.balance)} ${item?.currency??""}` : "N/A",
    },
    {
      name: "BUY IN",
      key: "buyInCount",
    },
    {
      name: "ACTIONS",
      render: (item: any, fetchData: any) => {
        return (
          <div className="d-flex align-items-center">
            <Button
              label="Confirm"
              buttonType="success"
              onClick={() => handleOnClickApproval(item, EApprovalActionType.APPROVE, fetchData)}
              disabled={item?.status !== "AGENT_REGISTERED"}
            />

            <Button
              className="ml10"
              label="Reject"
              buttonType="danger"
              onClick={() => handleOnClickApproval(item, EApprovalActionType.REJECT, fetchData)}
              disabled={item?.status !== "AGENT_REGISTERED"}
            />
          </div>
        );
      },
    },
  ];

  const handleOnScheduleAdded = () => {
    AdminService.getScheduleList({ page: 1, pageSize: 1000, tourId: +params?.id })
      .then((res: any) => {
        setScheduleData(res?.result);
      })
      .catch((err: any) => {
        CreateAlert({ message: err?.message, type: "danger" });
      });
  };

  const handleOnScheduleChanged = () => {
    AdminService.getScheduleList({ page: 1, pageSize: 1000, tourId: +params?.id })
      .then((res: any) => {
        setScheduleData(res?.result);
      })
      .catch((err: any) => {
        CreateAlert({ message: err?.message, type: "danger" });
      });
  };

  useEffect(() => {
    AdminService.getTournamentDetail(params?.id).then((res: any) => {
      setDataDetail(res?.result);
    });

    AdminService.getScheduleList({ page: 1, pageSize: 1000, tourId: +params?.id }).then((res: any) => {
      setScheduleData(res?.result);
    });
  }, [params]);

  useEffect(() => {
    setForceUpdateTable((prev) => !prev);
  }, [dataDetail]);

  if (dataDetail == null) return null;

  return (
    <>
      <div className="tournament-edit-page container-fluid">
        <div className="row">
          <div className="col-6">
            <div className="tournament-edit-page__main">
              <div className="title">Edit Tournament</div>
              <div className="form">
                <div className="row">
                  <div className="col-6 input-container">
                    <InputWraper label={"Tour"} inputProps={getInputProps("tourName")} component={InputText} />
                  </div>
                  <div className="col-6 input-container">
                    <div id="edit-tournament-status" className="InputWraper hasValue">
                      <div className="label">
                        <span>Tour Status</span>
                      </div>
                      <div className="wraper">
                        <span className={`status status--${dataDetail?.status?.toLowerCase()}`}>{dataDetail?.status ?? "N/A"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 input-container">
                    <InputWraper label={"Location"} inputProps={getInputProps("location")} component={InputText} />
                  </div>
                  <div className="col-6 input-container">
                  <InputWraper label={"Organization"} inputProps={getInputProps("organization")} renderInput={(props) => (<InputSelect {...props} options={organizationList} isClearable={false} />)} />
                  </div>
                  <div className="col-6 input-container">
                    <InputWraper label={"Start Time"} inputProps={getInputProps("startTime")} component={InputDateTime} />
                  </div>
                  <div className="col-6 input-container">
                    <InputWraper label={"End Time"} inputProps={getInputProps("endTime")} component={InputDateTime} />
                  </div>
                  <div className="col-6 input-container">
                    <InputWraper label={"GTD"} inputProps={getInputProps("prize")} component={InputNumber} />
                  </div>
                  <div className="col-6 input-container">
                    <InputWraper label={"Currency"} inputProps={getInputProps("currency")} renderInput={(props) => (<InputSelect {...props} options={currencyOptions} value={getInputProps("currency").value} />)} />
                  </div>
                  <div className="col-12 input-container">
                    <InputWraper label={"Promotion"} inputProps={getInputProps("promotion")} component={InputImageSingle} />
                  </div>
                  <div style={{ textAlign: "center" }} className="col-12">
                    <Button label="Edit" buttonType="success" onClick={handleSubmit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="tournament-edit-page__event">
              <div className="tournament-edit-page__event__title">Schedule & Event</div>
              <div className="tournament-edit-page__event__content">
                <div className="accordion" id="tournament-edit-page__event__content__schedule">
                  {scheduleData?.data?.map((item: any, idx: number) => (
                    <ScheduleItem key={uuidv4()} idx={idx} item={item} onScheduleChanged={() => handleOnScheduleChanged()} />
                  ))}
                </div>
                <AddSchedule tourId={+params?.id} onScheduleAdded={() => handleOnScheduleAdded()} />
              </div>
            </div>
          </div>
        </div>
        <div className="tournament-participant">
          <div className="tournament-participant__title" style={{ fontWeight: "700", fontSize: "24px", margin: "40px 0 16px", color: "#fff" }}>
            Tournament Participants
            <div className="tournament-participant__button" onClick={(e)=>{
              e.stopPropagation();
              AdminService.getTournamentDetail(params?.id).then((res: any) => {
                setDataDetail(res?.result);
              });
            }}>
              {<Icon.Reload/>}
            </div>
          </div>
          <div className="tournament-participant__table">
            <Table
              hasOrderColumn
              structure={participantStructure}
              forceUpdateTable={forceUpdateTable}
              data={{
                data: dataDetail?.members,
                count: dataDetail?.members?.length,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const AddSchedule = (props: any) => {
  const { getInputProps, handleSubmit, resetForm } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "name",
        validate: Yup.string().required("Must be provided"),
      },
    ],
    onSubmit: async (values: any) => {
      return AdminService.addSchedule({ tourId: +props?.tourId, name: values?.name }).then((res: any) => {
        props?.onScheduleAdded();
        resetForm();
        CreateAlert({ message: "Add schedule successfully.", type: "success" });
      });
    },
  });

  return (
    <div className="row add-schedule">
      <div className="col-8">
        <InputWraper placeholder="Enter schedule name" inputProps={getInputProps("name")} component={InputText} />
      </div>
      <div className="col-4">
        <Button className="wfull" icon={Icon.AddNewIcon} label="Add New Schedule" buttonType="success" onClick={handleSubmit} />
      </div>
    </div>
  );
};

const ScheduleItem = (props: any) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isOpenAddEvent, setIsOpenAddEvent] = useState<boolean>(false);
  const [editEventData, setEditEventData] = useState<any>(null);
  const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);

  const handleOnClickDeleteSchedule = (e: any, item: any) => {
    AdminService.deleteSchedule({ tourScheduleId: +item?.tourScheduleId })
      .then((res: any) => {
        props?.onScheduleChanged();
        CreateAlert({ message: "Delete schedule successfully.", type: "success" });
      })
      .catch((err: any) => {
        CreateAlert({ message: err?.message, type: "danger" });
      });
  };

  const handleOnClickEditCancel = () => {
    getInputProps("name").onChange(props?.item?.tourScheduleName);
    setEditMode(false);
  };

  const handleOnClickDeleteEvent = (item: any) => {
    AdminService.deleteEvent({ tourEventId: +item?.tourEventId })
      .then((res: any) => {
        item?.fetchData();
        CreateAlert({ message: "Delete event successfully.", type: "success" });
      })
      .catch((err: any) => {
        CreateAlert({ message: err?.message, type: "danger" });
      });
  };

  const { getInputProps, handleSubmit, resetForm } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "name",
        validate: Yup.string().required("Must be provided"),
        defaultValue: props?.item?.tourScheduleName,
      },
    ],
    onSubmit: async (values: any) => {
      let payload = {
        tourId: +props?.item?.tourId,
        tourScheduleId: +props?.item?.tourScheduleId,
        name: values?.name,
      };
      return AdminService.editSchedule(payload)
        .then((res: any) => {
          setEditMode(false);
          props?.onScheduleChanged();
          CreateAlert({ message: "Edit schedule successfully.", type: "success" });
        })
        .catch((err: any) => {
          CreateAlert({ message: err?.message, type: "danger" });
        });
    },
  });

  let eventStructure = [
    {
      name: "EVENT ID",
      key: "eventRef",
      render: (item: any) => item?.eventRef ?? "N/A",
    },
    {
      name: "EVENT NAME",
      key: "tourEventName",
      render: (item: any) => item?.tourEventName ?? "N/A",
    },
    {
      name: "BUY-IN+FEE",
      key: "buyInFee",
      render: (item: any) => (item?.buyInFee != null ? `${new Intl.NumberFormat("en-US").format(item?.buyInFee)} ${item?.currency ?? ""}` : "N/A"),
    },
    {
      name: "REG END",
      key: "regEnd",
      render: (item: any) => (item?.regEnd != null ? moment(item?.regEnd).format("L HH:mm:ss") : "N/A"),
    },
    {
      name: "START TIME",
      key: "startTime",
      render: (item: any) => (item?.startTime != null ? moment(item?.startTime).format("L HH:mm:ss") : "N/A"),
    },
    {
      name: "SPONSORSHIP",
      key: "isSponsorship",
      render: (item: any) => (item?.isSponsorship != null) ? (
        (item?.isSponsorship === true) && <span className="sponsorship"><Icon.CheckedBoxIcon /></span>
      ) : 'N/A',
    },
    {
      name: "ACTION",
      key: "action",
      render: (item: any, fetchData: any) => {
        return (
          <div className="action">
            <div className="action__edit mr8" onClick={() => setEditEventData({ ...item, fetchData })}>
              <Icon.ActionEditIcon />
            </div>
            <div className="action__delete" onClick={() => handleOnClickDeleteEvent({ ...item, fetchData })}>
              <Icon.DeleteIcon />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={`accordion-item ${editMode === true ? "editing" : ""}`} key={props?.idx}>
      <div className="accordion-item__action">
        {editMode === false ? (
          <div className="accordion-item__action__item accordion-item__action__item--edit" onClick={() => setEditMode(true)}>
            <Icon.ActionEditIcon />
          </div>
        ) : (
          <>
            <div className="accordion-item__action__item accordion-item__action__item--edit-confirm" onClick={() => handleSubmit()}>
              <Icon.ApplyIcon />
            </div>
            <div className="accordion-item__action__item accordion-item__action__item--edit-cancel" onClick={handleOnClickEditCancel}>
              <Icon.Remove />
            </div>
          </>
        )}
        <div
          className="accordion-item__action__item accordion-item__action__item--delete"
          onClick={(e) => handleOnClickDeleteSchedule(e, props?.item)}
        >
          <Icon.DeleteIcon />
        </div>
      </div>
      <h2 className={`accordion-header ${editMode === true ? "editing" : ""}`}>
        <button
          className={`accordion-button`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#panelsStayOpen-collapse${props?.idx}`}
          aria-expanded="true"
        >
          {/* {props?.item?.tourScheduleName} */}
          {editMode === true ? (
            <InputWraper className="mb0 mr8" placeholder="Enter schedule name" inputProps={getInputProps("name")} component={InputText} />
          ) : (
            <>{props?.item?.tourScheduleName}</>
          )}
          {/* <div className="accordion-button__title">{item?.tourScheduleName}</div>
                    <div className="accordion-button__action">
                        <div className="accordion-button__action__delete" onClick={(e) => handleOnClickDeleteSchedule(e, item)}><Icon.DeleteIcon /></div>
                    </div> */}
        </button>
      </h2>
      <div id={`panelsStayOpen-collapse${props?.idx}`} className="accordion-collapse collapse">
        <div className="accordion-body">
          <div className="d-flex justify-content-end">
            <Button className="mb20" label="Add Event" icon={Icon.AddNewIcon} buttonType="success" onClick={() => setIsOpenAddEvent(true)} />
          </div>
          <Table
            structure={eventStructure}
            forceUpdateTable={forceUpdateTable}
            fetchData={async (params) => {
              return AdminService.getEventList({
                tourScheduleId: +props?.item?.tourScheduleId,
                page: params.pageNumber,
                pageSize: params.limit,
                ...params,
              }).then((res: any) => {
                return {
                  data: res?.result?.data,
                  count: res?.result?.total,
                };
              });
            }}
          />
        </div>
      </div>
      {isOpenAddEvent && (
        <PopupWraper center title={`ADD EVENT FOR "${props?.item?.tourScheduleName}"`} onClose={() => setIsOpenAddEvent(false)}>
          <AddEvent
            item={props?.item}
            onFinish={() => {
              setForceUpdateTable((state) => !state);
              setIsOpenAddEvent(false);
            }}
          />
        </PopupWraper>
      )}
      {editEventData && (
        <PopupWraper center title={`EDIT EVENT`} onClose={() => setEditEventData(null)}>
          <EditEvent
            data={editEventData}
            onFinish={() => {
              editEventData.fetchData();
              setEditEventData(null);
            }}
          />
        </PopupWraper>
      )}
    </div>
  );
};

const AddEvent: FC<{ item: any; onFinish: () => void }> = (props) => {
  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "eventRef",
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "tourEventName",
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "buyInFee",
        validate: Yup.number().typeError("Must be a number").required("Must be provided").moreThan(0, "Must be greater than 0"),
      },
      {
        name: "currency",
        validate: Yup.string().required("Must be provided"),
        defaultValue: "VND",
        isDisabled: true,
      },
      {
        name: "regEnd",
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "startTime",
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "isSponsorship",
        validate: Yup.string().required(translate("must-be-provided")),
        defaultValue: true,
      },
    ],
    onSubmit: async (values) => {
      return AdminService.addEvent({
        tourScheduleId: +props.item?.tourScheduleId,
        eventRef: values.eventRef,
        tourEventName: values.tourEventName,
        buyInFee: +values.buyInFee,
        currency: values?.currency,
        regEnd: moment(values.regEnd).toISOString(),
        startTime: moment(values.startTime).toISOString(),
        isSponsorship: values.isSponsorship,
      })
        .then(() => {
          CreateAlert({
            message: "Add event successfully.",
            type: "success",
          });
          props.onFinish();
        })
        .catch((error) => {
          CreateAlert({
            message: translate(error.message),
            type: "danger",
          });
        });
    },
  });
  return (
    <form className="add-user" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <InputWraper label={"Event ID"} inputProps={getInputProps("eventRef")} component={InputText} />
        </div>
        <div className="col-12">
          <InputWraper label={"Event Name"} inputProps={getInputProps("tourEventName")} component={InputText} />
        </div>
        <div className="col-12">
          <InputWraper label={"Buy-In+Fee"} inputProps={getInputProps("buyInFee")} component={InputNumber} />
        </div>
        <div className="col-12">
          <InputWraper label={"Currency"} inputProps={getInputProps("currency")} renderInput={(props) => (<InputSelect {...props} options={currencyOptions} value={getInputProps("currency").value} />)} />
        </div>
        <div className="col-12">
          <InputWraper label={"Reg End"} inputProps={getInputProps("regEnd")} component={InputDateTime} />
        </div>
        <div className="col-12">
          <InputWraper label={"Start Time"} inputProps={getInputProps("startTime")} component={InputDateTime} />
        </div>
        <div className="col-12">
          <InputWraper 
            inputProps={getInputProps("isSponsorship")} 
            renderInput={(props) => (
              <InputCheckbox 
                {...props} 
                label="SPONSORSHIP & COMMISSION" 
                onChange={(value) => getInputProps("isSponsorship").onChange(value)}
              />
            )}
          />
        </div>
      </div>
      <div>
        <Button
          // className="mb20"
          label="Add Event"
          // onClick={props.onClick}
          isMiddle
          buttonType="success"
          className="btnBan"
          type="submit"
        />
      </div>
    </form>
  );
};

const EditEvent: FC<{ data: any; onFinish: () => void }> = (props) => {
  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "eventRef",
        validate: Yup.string().required(translate("must-be-provided")),
        defaultValue: props?.data?.eventRef,
      },
      {
        name: "tourEventName",
        validate: Yup.string().required(translate("must-be-provided")),
        defaultValue: props?.data?.tourEventName,
      },
      {
        name: "buyInFee",
        validate: Yup.number().typeError("Must be a number").required("Must be provided").moreThan(0, "Must be greater than 0"),
        defaultValue: props?.data?.buyInFee,
      },
      {
        name: "currency",
        validate: Yup.string().required("Must be provided"),
        defaultValue: props?.data?.currency,
        isDisabled: true,
      },
      {
        name: "regEnd",
        validate: Yup.string().required(translate("must-be-provided")),
        defaultValue: props?.data?.regEnd,
      },
      {
        name: "startTime",
        validate: Yup.string().required(translate("must-be-provided")),
        defaultValue: props?.data?.startTime,
      },
      {
        name: "isSponsorship",
        validate: Yup.string().required(translate("must-be-provided")),
        defaultValue: props?.data?.isSponsorship,
      },
    ],
    onSubmit: async (values) => {
      return AdminService.editEvent({
        tourScheduleId: +props.data?.tourScheduleId,
        tourEventId: +props.data?.tourEventId,
        eventRef: values.eventRef,
        tourEventName: values.tourEventName,
        buyInFee: +values.buyInFee,
        currency: values?.currency,
        regEnd: moment(values.regEnd).toISOString(),
        startTime: moment(values.startTime).toISOString(),
        isSponsorship: values.isSponsorship,
      })
        .then(() => {
          CreateAlert({
            message: "Edit event successfully.",
            type: "success",
          });
          props.onFinish();
        })
        .catch((error) => {
          CreateAlert({
            message: translate(error.message),
            type: "danger",
          });
        });
    },
  });
  return (
    <form className="add-user" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <InputWraper label={"Event ID"} inputProps={getInputProps("eventRef")} component={InputText} />
        </div>
        <div className="col-12">
          <InputWraper label={"Event Name"} inputProps={getInputProps("tourEventName")} component={InputText} />
        </div>
        <div className="col-12">
          <InputWraper label={"Buy-In+Fee"} inputProps={getInputProps("buyInFee")} component={InputNumber} />
        </div>
        <div className="col-12">
          <InputWraper label={"Currency"} inputProps={getInputProps("currency")} renderInput={(props) => (<InputSelect {...props} options={currencyOptions} value={getInputProps("currency").value} />)} />
        </div>
        <div className="col-12">
          <InputWraper label={"Reg End:"} inputProps={getInputProps("regEnd")} component={InputDateTime} />
        </div>
        <div className="col-12">
          <InputWraper label={"Start Time:"} inputProps={getInputProps("startTime")} component={InputDateTime} />
        </div>
        <div className="col-12">
          <InputWraper 
            inputProps={getInputProps("isSponsorship")} 
            renderInput={(props) => (
              <InputCheckbox 
                {...props} 
                label="SPONSORSHIP & COMMISSION" 
                onChange={(value) => getInputProps("isSponsorship").onChange(value)}
              />
            )}
          />
        </div>
      </div>
      <div>
        <Button
          // className="mb20"
          label="Edit Event"
          // onClick={props.onClick}
          isMiddle
          buttonType="success"
          className="btnBan"
          type="submit"
        />
      </div>
    </form>
  );
};
