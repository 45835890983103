import React, { memo, useEffect, useState } from "react";
import XLSX from "xlsx";
import { AdminService } from "../../services/admin";
import { Button, CreateAlert, Icon, Table } from "../../modules";
import moment from "moment";
import { Routes } from "../../AppRoutes";
import { useHistory } from "react-router-dom";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../components/table-filter-inputs/select";

export const SponsorshipAndCommissionList = () => {

    const [params, setParams] = useState([] as any[]);
    const [tournamentListOptions, setTournamentListOptions] = useState<any>();
    const [agentList, setAgentList] = useState<any>();
    const [summary, setSummary] = useState<any>();

    const structure = [
        {
            name: "FULL NAME",
            key: "firstName",
            render: (item: any) => <span style={{ color: '#4680FF' }}>{item?.firstName ?? 'N/A'}</span>,
            copyable: (item: any) => item?.firstName,
        },
        {
            name: "BUY-IN",
            key: "totalBuyIn",
            render: (item: any) => (item?.totalBuyIn != null) ? new Intl.NumberFormat().format(item?.totalBuyIn) : 'N/A',
            copyable: (item: any) => item?.totalBuyIn,
        },
        {
            name: "TIME",
            key: "created",
            render: (item: any) => (item?.created != null) ? moment(item?.created).format('L HH:mm:ss') : 'N/A',
            copyable: (item: any) => (item?.created != null) ? moment(item?.created).format('L HH:mm:ss') : 'N/A',
        },
        {
            name: "TOUR NAME",
            key: "tourName",
            render: (item: any) => item?.tourName ?? 'N/A',
            copyable: (item: any) => item?.tourName,
        },
        {
            name: "EVENT ID",
            key: "eventRef",
            render: (item: any) => item?.eventRef ?? "N/A",
            copyable: (item: any) => item?.eventRef,
        },
        {
            name: "PACKAGE NAME",
            key: "packageName",
            render: (item: any) => item?.packageName ?? "N/A",
            copyable: (item: any) => item?.packageName,
        },
        {
            name: "SPONSORSHIP (%)",
            key: "sponsorship",
            render: (item: any) => (item?.sponsorship != null) ? `${new Intl.NumberFormat().format(item?.sponsorship)}%` : 'N/A',
            copyable: (item: any) => item?.sponsorship,
        },
        {
            name: "SPONSORSHIP ESTIMATE",
            key: "sponsorshipEst",
            render: (item: any) => (item?.sponsorshipEst != null) ? new Intl.NumberFormat().format(item?.sponsorshipEst) : 'N/A',
            copyable: (item: any) => item?.sponsorshipEst,
        },
        {
            name: "COMMISSION (%)",
            key: "commission",
            render: (item: any) => (item?.commission != null) ? `${new Intl.NumberFormat().format(item?.commission)}%` : 'N/A',
            copyable: (item: any) => item?.commission,
        },
        {
            name: "COMMISSION ESTIMATE",
            key: "commissionEst",
            render: (item: any) => (item?.commissionEst != null) ? new Intl.NumberFormat().format(item?.commissionEst) : 'N/A',
            copyable: (item: any) => item?.commissionEst,
        },
    ];

    const handleExportExcel = async () => {
        return new Promise(async (resolve) => {
            try {
                const response = await AdminService.getSponsorshipAndCommissionList({
                    ...params,
                    page: 1,
                    pageSize: 10000,
                });

                const data = response.data;

                const fileHead = structure.map((v) => v.name);
                const dataExport = [
                    fileHead,
                    ...data.map((item: any) =>
                        structure.map((column, index) => {
                            if (!column.key) return "";
                            if (column.key === 'date') return `${(item?.startTime != null) ? moment(item.startTime).format("L") : 'N/A'} - ${(item?.endTime != null) ? moment(item.endTime).format("L") : 'N/A'}`;
                            return item[column.key];
                        })
                    ),
                ];

                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                const now = new Date();
                XLSX.writeFile(
                    wb,
                    `Tournament List ${now.toLocaleDateString().replace(/\//g, "-")} ${now
                        .toLocaleTimeString()
                        .replace(/:/g, "-")}.xlsx`,
                    { type: "binary" }
                );

                resolve(
                    CreateAlert({
                        type: "success",
                        message: "Export data success.",
                    })
                );
            } catch (error) {
                // @ts-ignore
                resolve(CreateAlert({ type: "danger", message: error.message }));
            }
        });
    };

    useEffect(() => {
        AdminService.getTournamentList({
            page: 1,
            pageSize: 100000,
        }).then((res) => {
            setTournamentListOptions(
                res?.data?.map((item: any) => ({
                    label: item.tourName,
                    value: item.tourId,
                }))
            );
        });

        AdminService.getAgentList().then((res) => {
            setAgentList((res?.result?.agents?.AGENTS ?? [])?.map((x: any) => ({ label: x.AGENT_NAME, value: x.AGENT_ID })));
        });
    }, []);

    if (tournamentListOptions == null || agentList == null) return null;

    return (
        <div className="sponsorship-and-commission-list">
            <div className="d-flex justify-content-between button-container">
                <Button
                    className="mb20"
                    label="Export to Excel"
                    buttonType="success"
                    onClick={handleExportExcel}
                />
                <div className="infor-container">
                    <div className="infor-container__item infor-container__item--sponsorship">
                        <div className="infor-container__item__icon">
                            <Icon.SponsorshipIcon />
                        </div>
                        <div className="infor-container__item__content">
                            {(summary?.totalSponsorshipEst != null) ? new Intl.NumberFormat().format(summary?.totalSponsorshipEst) : 0}
                            <span className="sub-text">&nbsp;TOTAL SPONSORSHIP ESTIMATE</span>
                        </div>
                    </div>
                    <div className="infor-container__item infor-container__item--commission">
                        <div className="infor-container__item__icon">
                            <Icon.CommissionIcon />
                        </div>
                        <div className="infor-container__item__content">
                            {(summary?.totalCommissionEst != null) ? new Intl.NumberFormat().format(summary?.totalCommissionEst) : 0}
                            <span className="sub-text">&nbsp;TOTAL COMMISSION ESTIMATE</span>
                        </div>
                    </div>
                </div>
            </div>

            <Table
                hasOrderColumn
                hasSearchButton
                isOpenFilter
                filters={[
                    {
                        name: "Search",
                        key: "search",
                        input: e => <TableFilterInputText {...e} placeholder="Full Name, Email" />,
                    },
                    {
                        name: "Tour",
                        key: "tourId",
                        input: (e) => (
                            <TableFilterInputSelect
                                {...e}
                                options={tournamentListOptions}
                                isClearable={true}
                                isSearchable={true}
                            />
                        ),
                    },
                    {
                        name: "Agent Name",
                        key: "agentId",
                        input: (e) => (
                            <TableFilterInputSelect
                                {...e}
                                options={agentList}
                                isClearable={true}
                                isSearchable={false}
                            />
                        ),
                    },
                    {
                        name: "Time",
                        key: "created",
                        input: (e) => (
                            <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />
                        ),
                        defaultValue: {
                            fromDate: moment().subtract(1, "months").toDate(),
                            toDate: moment().toDate(),
                        },
                    },
                ]}
                structure={structure}
                fetchData={async (params) => {
                    setParams(params);
                    return AdminService.getSponsorshipAndCommissionList({
                        page: params.pageNumber,
                        pageSize: params.limit,
                        ...params,
                    }).then((res) => {
                        setSummary(res?.summary);
                        return res;
                    });
                }}
            />
        </div>
    );
};
