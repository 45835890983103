import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";

export interface IConfigWraperProps extends RouteComponentProps { }

export const withBuyInWraper = (Component: FC<IConfigWraperProps>) => (props: IConfigWraperProps) => {

    return (
        <div className="withBuyInWraper">
            <div className="TabLinks">
                <NavLink to={Routes.buyIn.path} exact={true}>
                    <Icon.BuyInIcon />
                    Buy-in
                </NavLink>
                <NavLink to={Routes.buyInWithoutPackage.path} exact={true}>
                    <Icon.BuyInIcon />
                    Buy-in (without balance)
                </NavLink>
            </div>
            <Component {...props} />
        </div>
    );
};
