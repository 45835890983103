import React, { memo, useEffect, useState } from "react";
import XLSX from "xlsx";
import { AdminService } from "../../../services/admin";
import { Button, CreateAlert, Icon, InputWraper, Table, useForm } from "../../../modules";
import moment from "moment";
import { InputNumber, InputSelect, InputText, InputTextArea } from "../../../components";
import { PopupWraper } from "../../../components/popup";
import * as Yup from "yup";
import { EApprovalActionType, ETournamentPackageUserStatus } from "../../../types";
import { translate } from "../../../languages";
import { EditorForm } from "../../config/news/editor";
import parse from 'html-react-parser';
import { currencyOptions } from "../../tournament/create";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { getEnv } from "../../../configs";
import Lightbox from "yet-another-react-lightbox";
import { TableFilterInputText } from "../../../components/table-filter-inputs";
import BalanceText from "../../../components/balance";

export const PackageList = () => {

    const [tournamentList, setTourList] = useState<any>();
    const [createPackageData, setCreatePackageData] = useState<any>();
    const [editPackageData, setEditPackageData] = useState<any>();
    const [packageList, setPackageList] = useState<any>();
    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);
    const [currentTournament, setCurrentTournament] = useState<any>();
    const [currentPackage, setCurrentPackage] = useState<any>();
    const [isRequesting, setIsRequesting] = useState<boolean>(false);
    const [dependantDataPopup, setDependantDataPopup] = useState<any>(null);
    const [infoBeforePurchaseApprovalPopupData, setInfoBeforePurchaseApprovalPopupData] = useState<any>(null);
    const [openLightboxData, setOpenLightboxData] = useState<any>();

    let handleOnChangeTour = (value: any) => {
        setCurrentPackage(null);
        setCurrentTournament(value);
        setForceUpdateTable(state => !state);
        AdminService.getPackageList({ page: 1, pageSize: 1000000, tourId: +value }).then((res) => {
            setPackageList(res?.data ?? []);
        });
    };

    let handleOnClickMemberPurchased = (item: any) => {
        setCurrentPackage(item);
        setForceUpdateTable(state => !state);
    };

    let handleOnCreatePackage = () => {
        setCreatePackageData(tournamentList?.find((item: any) => item?.tourId == currentTournament));
    };

    let handleOnFinishCreatePackage = () => {
        setCreatePackageData(null);
        AdminService.getPackageList({ page: 1, pageSize: 1000000, tourId: +currentTournament }).then((res) => {
            setPackageList(res?.data ?? []);
        });
    };

    let handleOnFinishEditPackage = () => {
        setEditPackageData(null);
        AdminService.getPackageList({ page: 1, pageSize: 1000000, tourId: +currentTournament }).then((res) => {
            setPackageList(res?.data ?? []);
        });
    };

    let handleOnClickPreApproval = async (payload:any) => {
        setInfoBeforePurchaseApprovalPopupData({ ...payload });
    };

    let handleOnClickAproval = async (payload: any) => {

        const {
            packageId,
            userId,
            status,
            action,
            fetchData,
        } = payload;

        if ([ETournamentPackageUserStatus.JUST_BUYED, ETournamentPackageUserStatus.MODIFY].includes(status) === false) return;
        Swal.fire({
            title: `Are you sure to ${action} this user?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: (action === EApprovalActionType.APPROVE) ? 'Approve' : 'Reject',
            showLoaderOnConfirm: true,
            input: (action === EApprovalActionType.REJECT) ? 'text' : undefined,
            inputPlaceholder: (action === EApprovalActionType.REJECT) ? 'Enter reason for rejection' : undefined,
            heightAuto: false,
            preConfirm: async (value) => {
                let payload = {
                    packageId: +packageId,
                    userId: +userId,
                    status: (action === EApprovalActionType.APPROVE) ? ETournamentPackageUserStatus.CONFIRM : ETournamentPackageUserStatus.REJECTED,
                    ...(action === EApprovalActionType.REJECT) && { reason: value }
                };
                setIsRequesting(true);
                return AdminService.approvePurchasePackage(payload).then((res) => {
                    CreateAlert({ type: "success", message: `${(action === EApprovalActionType.APPROVE) ? "Approve" : "Reject"} success`, });
                    fetchData();
                    AdminService.getPackageList({ page: 1, pageSize: 1000000, tourId: +currentTournament }).then((res) => {
                        setPackageList(res?.data ?? []);
                    });
                }).catch((err) => {
                    CreateAlert({ type: "danger", message: translate(err.message), });
                }).finally(() => {
                    setIsRequesting(false);
                    setInfoBeforePurchaseApprovalPopupData(null);
                });
            },
        });
    };

    let structure:any = [
        {
            name: "FULL NAME",
            key: "firstName",
            render: (item: any) => item?.firstName ?? "N/A",
            copyable: (item: any) => item?.firstName,
        },
        {
            name: "PACKAGE",
            key: "packageName",
            render: (item: any, fetchData: any) => (<>
                {(item?.dependOf == null) && (
                    <div className="current-package-container">
                        <div className={`current-package current-package--${item?.purchasePackageStatus?.toLowerCase()}`}>
                            <div className="current-package__name">{item?.packageName}</div>
                            <div className={`current-package__status`}>
                                {(() => {
                                    switch (item?.purchasePackageStatus) {
                                        case ETournamentPackageUserStatus.JUST_BUYED:
                                            return <Icon.PendingStatusIcon />;
                                        case ETournamentPackageUserStatus.CONFIRM:
                                            return <Icon.ApprovedStatusIcon />;
                                        case ETournamentPackageUserStatus.REJECTED:
                                            return <Icon.RejectedStatusIcon />;
                                        case ETournamentPackageUserStatus.MODIFY:
                                            return <Icon.ModifyStatusIcon />;
                                        default:
                                            return "N/A";
                                    }
                                })()}
                            </div>
                            <div className="current-package__remove" onClick={() => handleOnClickRemovePackage(item, fetchData)}><Icon.TrashIcon /></div>
                        </div>
                        <div className="current-package__prize">{(item?.packagePrize != null) ? `${new Intl.NumberFormat('en-US', { minimumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY"), maximumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY") }).format(item?.packagePrize)} ${item?.currency}` : "N/A"}</div>
                    </div>
                    
                )}
            </>),
            copyable: (item: any) => (item?.dependOf == null) ? item?.packageName : null,
        },
        {
            name: "DEPENDANT",
            render: (item: any) => (<>
                {(item?.dependOf == null) && (<>
                    {(item?.dependant != null) ? (
                        <div className="bill-n-dependant">
                            <div className="bill-n-dependant__dependant" onClick={() => setDependantDataPopup(item)}>
                                <Icon.UserGroupIcon />
                            </div>
                        </div>
                    ) : "N/A"}
                </>)}
            </>),
        },
        {
            name: "BILL IMAGE",
            render: (item: any) => (<>
                {(item?.dependOf == null) && (
                    <div className="bill-n-dependant">
                        {(item?.billImage != null) ? (
                            <div className="bill-n-dependant__bill-image" onClick={() => setOpenLightboxData(JSON.parse((item?.billImage != null && item?.billImage != '') ? item?.billImage : '[]'))}>
                                <Icon.BillIcon />
                            </div>
                        ) : "N/A"}
                    </div>
                )}
            </>),
        },
        {
            name: "PURCHASED TIME",
            key: "nick",
            render: (item: any) => (item?.created != null) ? moment(item?.created).format('L HH:mm:ss') : "N/A",
            copyable: (item: any) => (item?.created != null) ? moment(item?.created).format('L HH:mm:ss') : "N/A",
        },
        {
            name: "BALANCE",
            key: "balanceAfter",
            render: (item: any) =>  <BalanceText amount={item?.balanceAfter}/>,
        },
        {
            name: "AGENT NAME",
            key: "agentName",
            render: (item: any) => item?.agentName ?? "N/A",
            copyable: (item: any) => item?.agentName,
        },
        {
            name: "ACTIONS",
            key: "actions",
            render: (item:any, fetchData:any) => {
                return (
                    <div className="action">
                        {(([ETournamentPackageUserStatus.JUST_BUYED, ETournamentPackageUserStatus.MODIFY].includes(item?.status)) && (item?.dependOf == null)) && (<>
                            <div
                                className="action__approve"
                                onClick={() => handleOnClickPreApproval({...item, action: EApprovalActionType.APPROVE, fetchData: fetchData })}
                            >
                                <Button
                                    label="Approve"
                                    buttonType="success"
                                    isLoading={isRequesting}
                                    disabled={isRequesting}
                                />
                            </div>
                            <div
                                className="action__reject"
                                onClick={() => handleOnClickAproval({...item, action: EApprovalActionType.REJECT, fetchData: fetchData })}
                            >
                                <Button
                                    label="Reject"
                                    buttonType="danger"
                                    isLoading={isRequesting}
                                    disabled={isRequesting}
                                />
                            </div>
                        </>)}
                    </div>
                );
            },
        },
    ];

    let handleOnClickRemovePackage = (item: any, fetchData: any) => {
        if (isRequesting === true) return;
        Swal.fire({
            title: `Are you sure you want to remove the package "<strong>${item?.packageName}</strong>" from representative user "<strong>${item?.firstName}</strong>", this user and all dependants will be removed from the tournament?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            showLoaderOnConfirm: true,
            heightAuto: false,
            preConfirm: async (value) => {
                let payload = {
                    userId: +item?.userId,
                    packageId: +item?.packageId,
                };
                setIsRequesting(true);
                return AdminService.removePackageByUser(payload).then((res) => {
                    CreateAlert({ type: "success", message: `Remove package successfully` });
                    fetchData();
                }).catch((err) => {
                    CreateAlert({ type: "danger", message: translate(err.message), });
                }).finally(() => {
                    setIsRequesting(false);
                });
            },
        });
    };

    useEffect(() => {
        AdminService.getTournamentList({ page: 1, pageSize: 1000000}).then((res) => {
            setTourList(res?.data ?? []);
        });
    }, []);

    return (
        <div className="package-page">
            <div className="package__tour">
                <div className="package__tour__title">TOUR:</div>
                <div className="package__tour__input">
                    <div className="package__tour__input__select">
                        <InputSelect
                            name=""
                            label="Select Tour"
                            onTouched={() => null}
                            options={tournamentList?.map((item: any) => ({ label: item.tourName, value: item.tourId }))}
                            onChange={(value) => handleOnChangeTour(value)}
                            value={currentTournament}
                            isClearable={false}
                        />
                    </div>
                    <div className="package__tour__input__create">
                        <Button
                            label="Create Package"
                            buttonType="success"
                            onClick={handleOnCreatePackage}
                            disabled={currentTournament == null}
                        />
                    </div>
                </div>
                
            </div>
            {(packageList != null && packageList.length > 0) && (
                <div className="package__list--wrapper">
                    <div style={{ fontSize: '18px', fontWeight: '700', color: 'rgb(255, 255, 255)', marginBottom: '32px' }}>PACKAGE:</div>
                    <div className="row row-cols-4 package__list">
                        {packageList?.map((item: any, index: number) => {
                            return (
                                <div className="col package__list__item-wrapper my-4">
                                    <div className={`package__list__item ${(+item?.packageId === +currentPackage?.packageId) ? 'active' : ''}`}>
                                        <div className="package__list__item__summary">
                                            <div className="package__list__item__summary__item">
                                                <div className="package__list__item__summary__item__label">Member:</div>
                                                <div className="package__list__item__summary__item__value">{(item?.totalConfirmedPurchaseCount != null) ? `${new Intl.NumberFormat('en-US').format(item?.totalConfirmedPurchaseCount)}` : 'N/A'}</div>
                                            </div>
                                            <div className="package__list__item__summary__item">
                                                <div className="package__list__item__summary__item__label">Vol:</div>
                                                <div className="package__list__item__summary__item__value">{(item?.totalConfirmedPurchaseVolume != null) ? `${new Intl.NumberFormat('en-US').format(item?.totalConfirmedPurchaseVolume)} ${item?.currency}` : 'N/A'}</div>
                                            </div>
                                        </div>
                                        <div className="package__list__item__edit" onClick={() => setEditPackageData(item)}><Icon.ActionEditIcon /></div>
                                        <div className="package__list__item__row">Package Name: <span className="highlight">{item?.packageName ?? 'N/A'}</span></div>
                                        <div className="package__list__item__row">Package Price: <span className="highlight">{(item?.packagePrize != null) ? `${new Intl.NumberFormat('en-US').format(item?.packagePrize)} ${item?.currency}` : 'N/A'}</span></div>
                                        <div className="package__list__item__row">Sponsorship: <span className="highlight">{item?.sponsorship ?? 'N/A'}%</span></div>
                                        <div className="package__list__item__row">Commission: <span className="highlight">{item?.commission ?? 'N/A'}%</span></div>
                                        <div className="package__list__item__description" dangerouslySetInnerHTML={{__html: item?.packageDescription ?? 'N/A'}}></div>
                                        <div className="d-flex justify-content-center mt12">
                                            <Button
                                                label="Members Purchased"
                                                buttonType="primary"
                                                onClick={() => handleOnClickMemberPurchased(item)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
            {(currentTournament != null) && (
                <div className="package__member">
                    {(currentPackage != null) && (
                        <div className="package__member__title">{currentPackage?.packageName}</div>
                    )}
                    <div className="package__member__title">Members Purchased</div>
                    <div className="package__member__table">
                        <Table
                            hasOrderColumn
                            isOpenFilter
                            filters={[
                                {
                                    name: "Search",
                                    key: "keyword",
                                    input: (e) => <TableFilterInputText {...e} placeholder="Full Name" />,
                                },
                            ]}
                            structure={structure}
                            forceUpdateTable={forceUpdateTable}
                            fetchData={async (params) => {
                                return AdminService.getMemberPurchasedList({
                                    tourId: +currentTournament,
                                    ...(currentPackage != null) && { packageId: currentPackage?.packageId },
                                    page: params.pageNumber,
                                    pageSize: params.limit,
                                    ...params,
                                });
                            }}
                        />
                    </div>
                </div>
            )}
            {(infoBeforePurchaseApprovalPopupData != null) && (
                <PopupWraper
                    title="Info Before Purchase Approval"
                    onClose={() => setInfoBeforePurchaseApprovalPopupData(null)}
                >
                    <Table
                        hasOrderColumn
                        isOpenFilter
                        structure={[
                            {
                                name: "FULL NAME",
                                key: "firstName",
                                render: (item: any) => item?.firstName ?? "N/A",
                                copyable: (item: any) => item?.firstName,
                            },
                            {
                                name: "AMOUNT",
                                render: (item: any) => (item?.amount != null) ? `${new Intl.NumberFormat('en-US').format(+item?.amount)}` : "N/A",
                                copyable: (item: any) => item?.amount,
                            },
                            {
                                name: "BALANCE BEFORE",
                                render: (item: any) =>  <BalanceText amount={item?.balance}/>,
                                copyable: (item: any) => item?.balance,
                            },
                            {
                                name: "BALANCE AFTER",
                                render: (item: any) =>  <BalanceText amount={item?.balanceAfter}/>,
                                copyable: (item: any) => item?.balanceAfter,
                            }
                        ]}
                        forceUpdateTable={forceUpdateTable}
                        fetchData={async (params) => {
                            return AdminService.getInfoBeforePurchaseApproval({
                                packageId: +infoBeforePurchaseApprovalPopupData?.packageId, 
                                userId: +infoBeforePurchaseApprovalPopupData?.userId,
                                page: params.pageNumber,
                                pageSize: params.limit,
                                ...params,
                            });
                        }}
                    />
                    <Button
                        isMiddle
                        className="mt32"
                        label="Approve"
                        buttonType="success"
                        onClick={() => handleOnClickAproval(infoBeforePurchaseApprovalPopupData)}
                    />
                </PopupWraper>
            )}
            {(createPackageData != null) && (
                <PopupWraper
                    title={`Create Package for "${createPackageData?.tourName}"`}
                    onClose={() => setCreatePackageData(null)}
                >
                    <CreatePackage data={createPackageData} onFinish={() => handleOnFinishCreatePackage()} />
                </PopupWraper>
            )}
            {(editPackageData != null) && (
                <PopupWraper
                    title={`Edit Package for "${editPackageData?.packageName}"`}
                    onClose={() => setEditPackageData(null)}
                >
                    <EditPackage data={editPackageData} onFinish={() => handleOnFinishEditPackage()} />
                </PopupWraper>
            )}
            {(dependantDataPopup != null) && (
                <PopupWraper
                    title="Dependant"
                    onClose={() => setDependantDataPopup(null)}
                >
                    <DependantDataPopup data={dependantDataPopup} />
                </PopupWraper>
            )}
            <Lightbox
                open={openLightboxData != null}
                close={() => setOpenLightboxData(null)}
                slides={[
                    ...(openLightboxData ?? []).map((x: any) => ({ src: x })),
                ]}
                carousel={{
                    finite: true,
                }}
            />
        </div>
    )
};

const CreatePackage = (props: any) => {

    const { getInputProps, handleSubmit, isSubmitting } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: "packageName",
                validate: Yup.string().required("Must be provided"),
            },
            {
                name: "packageDescription",
                validate: Yup.string().required("Must be provided"),
            },
            {
                name: "packagePrize",
                validate: Yup.number()
                    .typeError("Must be a number")
                    .required("Must be provided")
                    .min(0, "Must be greater than or equal to 0"),
            },
            {
                name: "currency",
                validate: Yup.string().required("Must be provided"),
                defaultValue: "VND",
                isDisabled: true,
            },
            {
                name: "sponsorship",
                validate: Yup.number()
                    .typeError("Must be a number")
                    .required("Must be provided")
                    .min(0, "Must be greater than or equal to 0"),
            },
            {
                name: "commission",
                validate: Yup.number()
                    .typeError("Must be a number")
                    .required("Must be provided")
                    .min(0, "Must be greater than or equal to 0"),
            },
        ],
        onSubmit: async (values: any) => {
            let payload = {
                tourId: +props?.data?.tourId,
                packageName: values?.packageName,
                packageDescription: values?.packageDescription,
                packagePrize: +values?.packagePrize,
                currency: values?.currency,
                sponsorship: +values?.sponsorship,
                commission: +values?.commission,
            };

            return AdminService.createPackageByTournament(payload)
                .then((res: any) => {
                    props?.onFinish();
                    CreateAlert({ message: "Create package successfully.", type: "success" });
                })
                .catch((err: any) => {
                    CreateAlert({ message: err?.message, type: "danger" });
                })
                .finally(() => {
                    
                });
        },
    });

    return (
        <div className="create-package">
            <InputWraper
                label={"Package Name"}
                inputProps={getInputProps("packageName")}
                component={InputText}
            />
            <InputWraper
                label={"Package Description"}
                inputProps={getInputProps("packageDescription")}
                // component={InputTextArea}
                renderInput={(state: any) => (
                    <EditorForm
                        dataDefault={'<p></p>'}
                        getData={(data: any) => getInputProps("packageDescription").onChange(data)}
                    />
                )}
            />
            <InputWraper
                label={"Package Price"}
                inputProps={getInputProps("packagePrize")}
                component={InputNumber}
            />
            <InputWraper
                label={"Currency"}
                inputProps={getInputProps("currency")}
                renderInput={(props) => (
                    <InputSelect
                        {...props}
                        options={currencyOptions}
                        menuPlacement={"top"}
                        value={getInputProps("currency").value}
                    />
                )}
            />
            <InputWraper
                label={"Sponsorship Value (%)"}
                inputProps={getInputProps("sponsorship")}
                component={InputNumber}
            />
            <InputWraper
                label={"Commission Value (%)"}
                inputProps={getInputProps("commission")}
                component={InputNumber}
            />
            <div className="d-flex justify-content-center">
                <Button
                    label="Create Package"
                    buttonType="success"
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                />
            </div>
        </div>
    )
};

const EditPackage = (props: any) => {

    const { getInputProps, handleSubmit, isSubmitting } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: "packageName",
                validate: Yup.string().required("Must be provided"),
                defaultValue: props?.data?.packageName,
            },
            {
                name: "packageDescription",
                validate: Yup.string().required("Must be provided"),
                defaultValue: props?.data?.packageDescription ?? '<p></p>',
            },
            {
                name: "packagePrize",
                validate: Yup.number()
                    .typeError("Must be a number")
                    .required("Must be provided")
                    .min(0, "Must be greater than or equal to 0"),
                defaultValue: +props?.data?.packagePrize,
            },
            {
                name: "currency",
                validate: Yup.string().required("Must be provided"),
                defaultValue: props?.data?.currency,
                isDisabled: true,
            },
            {
                name: "sponsorship",
                validate: Yup.number()
                    .typeError("Must be a number")
                    .required("Must be provided")
                    .min(0, "Must be greater than or equal to 0"),
                defaultValue: +props?.data?.sponsorship,
            },
            {
                name: "commission",
                validate: Yup.number()
                    .typeError("Must be a number")
                    .required("Must be provided")
                    .min(0, "Must be greater than or equal to 0"),
                defaultValue: +props?.data?.commission,
            },
        ],
        onSubmit: async (values: any) => {
            let payload = {
                tourId: +props?.data?.tourId,
                packageId: +props?.data?.packageId,
                packageName: values?.packageName,
                packageDescription: values?.packageDescription,
                packagePrize: +values?.packagePrize,
                currency: values?.currency,
                sponsorship: +values?.sponsorship,
                commission: +values?.commission,
            };

            return AdminService.editPackageByTournament(payload)
                .then((res: any) => {
                    props?.onFinish();
                    CreateAlert({ message: "Edit package successfully.", type: "success" });
                })
                .catch((err: any) => {
                    CreateAlert({ message: err?.message, type: "danger" });
                })
                .finally(() => {
                    
                });
        },
    });

    return (
        <div className="edit-package">
            <InputWraper
                label={"Package Name"}
                inputProps={getInputProps("packageName")}
                component={InputText}
            />
            <InputWraper
                label={"Package Description"}
                inputProps={getInputProps("packageDescription")}
                renderInput={(state: any) => (
                    <EditorForm
                        dataDefault={getInputProps("packageDescription").value ?? '<p></p>'}
                        getData={(data: any) => getInputProps("packageDescription").onChange(data)}

                    />
                )}
            />
            <InputWraper
                label={"Package Prize"}
                inputProps={getInputProps("packagePrize")}
                component={InputNumber}
            />
            <InputWraper
                label={"Currency"}
                inputProps={getInputProps("currency")}
                renderInput={(props) => (
                    <InputSelect
                        {...props}
                        options={currencyOptions}
                        menuPlacement={"top"}
                        value={getInputProps("currency").value}
                    />
                )}
            />
            <InputWraper
                label={"Sponsorship Value (%)"}
                inputProps={getInputProps("sponsorship")}
                component={InputNumber}
            />
            <InputWraper
                label={"Commission Value (%)"}
                inputProps={getInputProps("commission")}
                component={InputNumber}
            />
            <div className="d-flex justify-content-center">
                <Button
                    label="Edit Package"
                    buttonType="success"
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                />
            </div>
        </div>
    )
};

export const DependantDataPopup = (props: any) => {

    let structure: any = [
        {
            name: "FULL NAME",
            key: "firstName",
            render: (item: any) => (item?.firstName != null) ? (
                <div className="user">
                    <div className="user__name">{item?.firstName}</div>
                    {(item?.isRepresentative === true) && (<div className="user__badge" title="This user is a representative"><Icon.RepresentativeIcon /></div>)}
                </div>
            ) : 'N/A',
            copyable: (item: any) => item?.firstName,
        },
        {
            name: "PACKAGE",
            key: "packageName",
            render: (item: any, fetchData: any) => (
                <div className="current-package-container">
                    <div className={`current-package current-package--${item?.purchasePackageStatus?.toLowerCase()}`}>
                        <div className="current-package__name">{item?.packageName}</div>
                        <div className={`current-package__status`}>
                            {(() => {
                                switch (item?.purchasePackageStatus) {
                                    case ETournamentPackageUserStatus.JUST_BUYED:
                                        return <Icon.PendingStatusIcon />;
                                    case ETournamentPackageUserStatus.CONFIRM:
                                        return <Icon.ApprovedStatusIcon />;
                                    case ETournamentPackageUserStatus.REJECTED:
                                        return <Icon.RejectedStatusIcon />;
                                    case ETournamentPackageUserStatus.MODIFY:
                                            return <Icon.ModifyStatusIcon />;
                                    default:
                                        return "N/A";
                                }
                            })()}
                        </div>
                    </div>
                    <div className="current-package__prize">{(item?.packagePrize != null) ? `${new Intl.NumberFormat('en-US', { minimumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY"), maximumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY") }).format(item?.packagePrize)} ${item?.currency}` : "N/A"}</div>
                </div>
            ),
            copyable: (item: any) => item?.packageName,
        },
        {
            name: "PURCHASED TIME",
            key: "created",
            render: (item: any) => (item?.created != null ? moment(item?.created).format("L HH:mm:ss") : "N/A"),
            copyable: (item: any) => (item?.created != null ? moment(item?.created).format("L HH:mm:ss") : "N/A"),
        },
        {
            name: "AMOUNT",
            key: "amount",
            render: (item: any) => (item?.amount != null) ? `${new Intl.NumberFormat('en-US').format(item?.amount)} ${item?.currency}` : "N/A",
            copyable: (item: any) => item?.amount,
        },
        {
            name: "BALANCE",
            key: "balanceAfter",
            render: (item: any) =>  <BalanceText amount={item?.balanceAfter}/>,
            copyable: (item: any) => item?.balanceAfter,
        },
    ];

    return (
        <div className="dependant-data-popup">
            <div className="button-container">
                <div className="infor-container">
                    <div className="balance">
                        <div className="balance__icon">
                            <Icon.DollarIcon />
                        </div>
                        <div className="balance__content">
                            {(() => {
                                let totalBalance = [{ ...props?.data, isRepresentative: true }, ...props?.data?.dependant].reduce((acc: any, item: any) => acc + Number(item?.balanceAfter), 0);
                                return `${new Intl.NumberFormat('en-US', { minimumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY"), maximumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY") }).format(totalBalance)} ${props?.data?.currency}`;
                            })()}
                            <span className="sub-text">&nbsp;TOTAL BALANCE</span>
                        </div>
                    </div>
                </div>
            </div>
            <Table
                hasOrderColumn
                isOpenFilter
                structure={structure}
                data={{
                    data: [{ ...props?.data, isRepresentative: true }, ...props?.data?.dependant],
                    count: [{ ...props?.data, isRepresentative: true }, ...props?.data?.dependant].length,
                }}
            />
        </div>
    )
};